module.exports = {
  en: {
    locale: "en-US",
    messages: {
      business: "Business"
    }
  },
  zh: {
    locale: "zh",
    messages: {
      business: "Business"
    }
  }
};
