import { StyleSheet } from 'aphrodite';
import { Colors } from '../../../../theme';
import { DM_FILTER_BAR_HEIGHT, DM_SIDE_BAR_WIDTH } from '../../../../constants';

export default StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'red',
    width: '100%'
  }
});
